import api from '@/services/api/api';

export default defineNuxtRouteMiddleware(async (to) => {
  const authStore = useAuthStore();
  // авторизация через социальные сети
  if (to.query.type === 'social_login') {
    const token = to.query.token;
    if (token) {
      try {
        await authStore.setLoggedIn(token);
        // await this.$showNotification('success', 'Вы успешно авторизовались')
      } catch (e) {
        // this.$showNotification(
        //   'error',
        //   `Возникла ошибка при попытке авторизоваться: ${e}.`
        // )
      }
    }
    return navigateTo('/');
  }
  // обработка переходов на сайт по ссылке из писем подтверждения почты или восстановления пароля
  // if (route.query.type === 'email_confirm') {
  //   const emailFromUrlParams = route.query.email_verified
  //   if (emailFromUrlParams) {
  //     // this.$showNotification('success', 'Вы успешно подтвердили свой аккаунт.')
  //   } else {
  //     // this.$showNotification(
  //     //   'error',
  //     //   'Возникла ошибка при подтверждении аккаунта.'
  //     // )
  //   }
  //   return redirect('/')
  // } else if (route.query.type === 'reset_password') {
  //   await store.dispatch('modal/showNewPasswordForm')
  // }

  // vk id
  const vkIdPayload = route.query.payload;
  if (vkIdPayload) {
    const vkIdPayloadParsed = JSON.parse(vkIdPayload);
    // IDK why but we need this
    const uuid = JSON.parse(JSON.stringify(vkIdPayloadParsed.uuid));
    const { data } = await api.auth.vkIdAuth({
      token: vkIdPayloadParsed.token,
      uuid,
      user: vkIdPayloadParsed.user,
    });
    if (data?.url) {
      return redirect(data.url);
    }
  }
});
